import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';


import { PresaleData } from '@/type/interface';
import { SERVER_URL } from '@/constants';
import { RootState } from './store';

export const firstPresalesFetch = createAsyncThunk(
  'presales/firstPresalesFetch',
  async () => {
    const response = await axios.get(`${SERVER_URL}/v1/presales`);
    return response.data;
  }
);

interface PresalesState {
  value: Array<PresaleData>;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState:PresalesState = {
    value : [],
    status: 'idle',
    error: null,
}

export const presalesSlice = createSlice({
  name: 'presales',
  initialState,
  reducers: {
    setPresale(state, action: PayloadAction<PresaleData>) {
      const existPresale = state.value.find((el) => el.contract_address === action.payload.contract_address);
      if (!existPresale) {
        state.value = [...state.value, action.payload];
      }
      else{
        state.value = state.value.map((el) => {
          if (el.contract_address === action.payload.contract_address) {
            el = action.payload
          }
          return el
        })
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(firstPresalesFetch.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(firstPresalesFetch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload.presales;
      })
      .addCase(firstPresalesFetch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch exchange rate';
      });
  },
});

export const { setPresale } = presalesSlice.actions;

export const getPresaleByAddress = (address:string) => (state: RootState) => state.presales.value.find((el:any) => el.contract_address === address)



export default presalesSlice.reducer;
