import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { firstPresalesFetch, getPresaleByAddress } from "@/store/presales";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PresalePageComponent from "../PresalePageComponent";
import "@/css/components/layout/OneLayout.scss";
import PresalePageWalletAction from "../PresalePageWalletAction";
import { Referal } from "../ui/Referal";
import WithDurevFull from "../WithDurevFull";
import { Helmet } from "react-helmet-async";
import TitleCard from "../ui/TitleCard";

const OneLayout = () => {
  const { pageName } = useParams();
  const presales = useAppSelector((state) => state.presales.value);
  const navigate = useNavigate();

  const currentPresale = useAppSelector((state) =>
    getPresaleByAddress(pageName || "")(state)
  );
  const status = useAppSelector((state) => state.presales.status);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (presales.length === 0) {
      dispatch(firstPresalesFetch());
    }
  }, []);

  useEffect(() => {
    if ((status === "failed") || (status === "succeeded" && !currentPresale)) {
      navigate("*");
    }
  }, [status]);

  return (
    <div className="wrap one-presale">
      {status === "succeeded" && currentPresale && (
        <>
          <Helmet>
            <title>
              {currentPresale.jetton.name}-{currentPresale.jetton.description}:
              durev launchpad
            </title>
            <meta
              name="description"
              content={`${currentPresale.jetton.name}-${currentPresale.jetton.description}: durev launchpad`}
            />
          </Helmet>
          <div className="one-presale__main">
            <PresalePageComponent data={currentPresale} />
            <WithDurevFull />
          </div>

          <aside className="one-presale__sidebar">
            <div className="presale-section presale-section__only-mobile br20">
              <div className="presale-section__image br16">
                <img src={currentPresale.image_url} />
              </div>
              <div className="presale-section__header">
                <TitleCard data={currentPresale.jetton} showDescription={true} />
                <div className="presale-section__header__social">
                  {
                    currentPresale.links && 
                    currentPresale.links.map((link, index) => {
                      return (
                        <a href={link.url}>
                          <span className={`${link.name} br-c`} />
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <PresalePageWalletAction data={currentPresale} />
            <Referal data={currentPresale} />
          </aside>
        </>
      )}
    </div>
  );
};
export default OneLayout;