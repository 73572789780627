import React from "react";
import "@/css/components/ui/ProgressBar.scss";
import { fromNano } from "@ton/ton";
interface ProgrresBarProps {
  softCap: number;
  hardCap: number;
  total: number;
}

const ProgressBar: React.FC<ProgrresBarProps> = ({
  softCap,
  hardCap,
  total,
}) => {
  const calculateProgress = () => {
    if (total < softCap) {
      const softCapProgress = (total / softCap) * 100;
      return `${softCapProgress.toFixed(2)}% of soft cap`;
    } else {
      const hardCapProgress = (total / hardCap) * 100;
      return `${hardCapProgress.toFixed(2)}% of hard cap`;
    }
  };

  const tmplCapIcon = (type: string, left?: number) => {
    const style = left !== undefined ? { left: `${left}%` } : {};
    return <span className={`cap-icon ${type}`} style={style}></span>;
  };

  const tmplCap = (count: number, type: string) => {
    return (
      <div className="footer-cap">
        <div className="footer-cap__title">
          {tmplCapIcon(type)} {type} cap
        </div>
        <div className="footer-cap__value">{fromNano(count)} TON</div>
      </div>
    );
  };

  const calculateProgressWidth = () => {
    return Math.min((total / hardCap) * 100, 100);
  };
  const calculatePositionSoft = () => {
    return Math.min((softCap / hardCap) * 100, 100);
  };

  const progressMessage = calculateProgress();
  const progressWidth = calculateProgressWidth();
  const positionSoft = calculatePositionSoft();

  return (
    <div className="progress-bar">
      <header>
        Progress<span className="dot"></span>
        <span className="value">{progressMessage}</span>
      </header>
      <div>{fromNano(total)} TON</div>
      <div className="progress-bar__bar">
        <div
          className="progress-bar__fill"
          style={{ width: `${progressWidth}%` }}
        ></div>
        {tmplCapIcon("soft", positionSoft)}
        {tmplCapIcon("hard", 97)}
      </div>
      <footer>
        {tmplCap(softCap, "soft")}
        {tmplCap(hardCap, "hard")}
      </footer>
    </div>
  );
};

export default ProgressBar;
