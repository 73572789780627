import { SERVER_URL, SERVICE_URL } from "@/constants";
import { useAppDispatch } from "@/hooks/hooks";
import { addToast, removeToast } from "@/store/toast";
import axios from "axios";
import { useEffect, useState } from "react";

interface CopyFieldProps{
    text: string;
}

export default function ReferalLink({text}:CopyFieldProps){
    const dispatch = useAppDispatch()
    const [refName, setRefName] = useState('')

    useEffect(() => {
        axios.get(`${SERVER_URL}/v1/user/refLink`, {
            headers: {
                'Authorization': `${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
                'accept': 'application/json',
            }
        }).then(res => res.data).then(data => setRefName(SERVICE_URL + '/?ref=' + data.ref_link.name))
    },[])

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
        const id = Date.now()
        const toast = {
            id: id,
            message: ('Link Copied')
        }
        dispatch(addToast(toast))
        setTimeout(() => dispatch(removeToast(id)), 3000)
    }

    return (
        <div className="referal-link">
            <div className="referal-link__text">
                <span className="icon-link"></span>
                <span>{refName}</span>
            </div>
            <span onClick={() => copyToClipboard(refName)} className="icon-copy"></span>
        </div>
    )
}