import { Link } from "react-router-dom";
import img from "../../assets/404.png"
import '@/css/components/layout/NotFoundPage.scss';

export default function NotFoundPage() {
    return (
        <div className="not-found">
            <img src={img} alt=""/>
            <Link className='exchange-durev__link-buy br25' to="/">
                <p>Back to home</p>
                <span className="icon-arrow br-c"></span>
            </Link>
        </div>
    );
}