import React from 'react';
import '@/css/components/WithDurev.scss';
import {ADD_UR_PROJ} from '@/constants';
import bg from '@/assets/WithDurev.png';

const WithDurev = () => {

    return(
        <div className='pre-card br20 with-durev' style={{backgroundImage:  `url(${bg})`}}>
            <a className='br25' href={ADD_UR_PROJ}>add ur proj <span className="icon-arrow br-c"></span></a>
            {/* <p>launch <br/>with durev</p> */}
        </div>
    );
};

export default WithDurev;
