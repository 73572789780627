import React from 'react';
import '@/css/components/WithDurev.scss';
import {ADD_UR_PROJ} from '@/constants';
import bg from '@/assets/WithDurev.png';

const WithDurevFull = () => {

    return(
        <div className='br20 with-durev full'>
            <img src={bg} alt="" />
            <div> 
                <p>launch with durev</p>
                <a className='br25' href={ADD_UR_PROJ}>add ur proj <span className="icon-arrow br-c"></span></a>
            </div>
           
            <img src={bg} alt="" />
        </div>
    );
};

export default WithDurevFull;
