import {StatusPresale} from '@/type/interface'

// TODO: testnet=false before production
export const CLIENT_ENDPOINT = `https://${(process.env.REACT_APP_TESTNET==='true') ? "testnet." : ""}toncenter.com/api/v2/jsonRPC`
export const SERVER_URL = process.env.REACT_APP_TESTNET==='true' ? process.env.REACT_APP_SERVER_URL_TEST :  process.env.REACT_APP_SERVER_URL
export const SERVICE_URL = process.env.REACT_APP_TESTNET==='true' ? process.env.REACT_APP_SERVICE_URL_TEST :  process.env.REACT_APP_SERVICE_URL
export const WS_URL = process.env.REACT_APP_TESTNET==='true' ? process.env.REACT_APP_WS_URL_TEST :  process.env.REACT_APP_WS_URL

export const SUBSCRIBE_URLS = [
    {
        icon_class: 'icon-telegram',
        url: 'https://t.me/poveldurev'
    },
    {
        icon_class: 'icon-x',
        url: 'https://x.com/poveldurev'
    }
];

export const ADD_UR_PROJ = process.env.REACT_APP_ADD_UR_PROJ;

export const BUY_DUREV = {
    title: 'buy $durev',
    url: 'https://dedust.io/swap/TON/DUREV'
};



export const STATUS_PRESALE: StatusPresale = {
    0: {
      title: 'start soon',
      class: 'start-soon',
      class_icon: 'icon-soon-status',
      button: false,
      hasInput: false
    },
    1: {
      title: 'active pre-sale',
      class: 'active_pre-sale',
      class_icon: 'icon-pre-sale-status',
      button: {
        class_icon: 'icon-participate',
        title: 'participate'
      },
      hasInput: true
    },
    2: {
      title: 'calc tokens',
      class: 'calc_tokens',
      class_icon: 'icon-calc-status',
      button: false,
      hasInput: false
    },
    3:{
      title: 'calc tokens',
      class: 'calc_tokens',
      class_icon: 'icon-calc-status',
      button: false,
      hasInput: false
    },
    4: {
      title: 'pre-sale ended',
      class: 'pre-sale_ended',
      class_icon: 'icon-ended-status',
      button: {
        title: 'claim tokens',
        class_icon: 'icon-claim'
      }, 
      hasInput: false
    },
    5: {
      title: 'pre-sale failed',
      class: 'pre-sale_failed',
      class_icon: 'icon-failed-status',
      button: false,
      hasInput: false
    },
  };