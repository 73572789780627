import React from 'react';
import { StatusPresale } from '@/type/interface';
import '@/css/components/ui/ButtonCard.scss'
import {STATUS_PRESALE} from '@/constants'
import { Link } from 'react-router-dom';
interface ButtonCardProps {
    status: number,
    contract_address: string
  }
const ButtonCard: React.FC<ButtonCardProps> = ({status, contract_address}) => {
    return(
        <footer className='pre-card__button'>
            <Link to={`${contract_address}`} >
                <p className={`br30 ${STATUS_PRESALE[status].class}`}>
                    <span className={STATUS_PRESALE[status].class_icon}></span>
                    {STATUS_PRESALE[status].title}
                </p>
                <span className={`icon-arrow br-c ${STATUS_PRESALE[status].class}`}></span>
            </Link>
        </footer>
    );
}
export default ButtonCard;