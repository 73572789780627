import React from 'react';
import TitleCard from '@/components/ui/TitleCard';
import ButtonCard from '@/components/ui/ButtonCard';
import ProgressBar from '@/components/ui/ProgressBar';
import '@/css/components/PreCard.scss'
import { PresaleData, TokenomicsItem } from '@/type/interface';
import { fromNano } from '@ton/core';

interface PreCardProps {
    data: PresaleData;
}

const PreCard: React.FC<PreCardProps> = ({ data }) => {

    const total_for_buyers = data.tokens_for_sale - (data.tokens_for_sale / (10^9));
    
    const price = (total_for_buyers / data.total).toFixed(2);

    const checkLiquidity = (): number | false => {
        if (data.tokenomics_items) {
          const liquidityItem = data.tokenomics_items.find(
            (item: TokenomicsItem) => item.is_liquidity === true
          );
          return liquidityItem ? liquidityItem.value : false;
        }
        return false;
      };

    const liquidityValue = checkLiquidity();

    return (
        <article className='br20 pre-card'>
            <TitleCard data={data.jetton} showDescription={false} />
            <div className="br16 progress">
                <ProgressBar softCap={data.softcap} hardCap={data.hardcap} total={data.total}/>
            </div>
            <div className="br16 progress-line">
                <div className="progress-line__item">
                    <span className="title">Token price</span>
                    <span className="value">{price} TON</span>
                </div>
                <div className="progress-line__item">
                    <span className="title">Offered</span>
                    <span className="value">{data.tokens_for_sale} {data.jetton.symbol}</span>
                </div>
                {
                    liquidityValue && (
                        <div className="progress-line__item">
                            <span className="title">Liquidity</span>
                            <span className="value">{liquidityValue}</span>
                        </div>
                    )
                }
            </div>
            <ButtonCard status={data.status} contract_address={data.contract_address}/>
        </article>
    );
};

export default PreCard;
