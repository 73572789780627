import React from "react"

import '@/css/components/ui/Toast.scss'
import { useAppDispatch } from "@/hooks/hooks";
import { removeToast } from "@/store/toast";

interface ToastProps{
    text: string;
    id: number;
}

const Toast:React.FC<ToastProps> = ({text, id}) => {

    const dispatch = useAppDispatch();
    const handleRemoveToast = () => {
        dispatch(removeToast(id))
    }
    return(
        <div className="toast">
            <div className="toast__content">
                <p>{text}</p>
            </div>
            <button onClick={handleRemoveToast}>X</button>
        </div>
    )
}

export default Toast