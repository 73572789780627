import {TonConnectUI} from "@tonconnect/ui-react";
import {TonConnectSender} from "@/contracts/senders/TonConnectSender";
import {Master} from "@/contracts/wrappers/Master";
import {Address, toNano} from "@ton/core";
import {TonClient} from "@ton/ton";
import {CLIENT_ENDPOINT} from "@/constants";

// совершить покупку
export const provideTon = async (tonConnect: TonConnectUI, presaleAddress: Address, amount: number, referrer?: Address) => {
    const client = new TonClient({
        endpoint: CLIENT_ENDPOINT,
    });

    const sender = new TonConnectSender(tonConnect)
    const contract = Master.createFromAddress(presaleAddress)
    const openedContract = client.open(contract)

    // query_id опциональный параметр, который облегчает индексацию блокчейна в некоторый случаях
    // Например: получить всю цепочку транзакций.
    const queryId = BigInt(Math.floor(Math.random() * 100000000))

    // пришлет запрос на отправку транзакции пользователю
    // 0.2 TON - network fee
    await openedContract.sendProvideTon(sender, toNano("0.2") + toNano(amount), queryId, toNano(amount), referrer)
}