import { PresaleData } from "@/type/interface";
import { SERVER_URL, STATUS_PRESALE } from "@/constants";
import "@/css/components/PresalePageWalletAction.scss";
import Countdown from "./ui/Countdown";
import ProgressBar from "./ui/ProgressBar";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";
import { TonConnectSender } from "@/contracts/senders/TonConnectSender";
import { SenderArguments } from "@ton/core";
import { fromNano, Address } from "@ton/ton";
import { provideTon } from "@/handlers/buy";

interface PresalePageWalletActionProps {
  data: PresaleData;
}

export default function PresalePageWalletAction({
  data,
}: PresalePageWalletActionProps) {
  const userFriendlyAddress = useTonAddress();
  const [tonConnectUi, setOptions] = useTonConnectUI();
  const [amount, setAmount] = useState(0);
  const [refferer, setRefferer] = useState("");

  const UnathorizeButton = {
    class_icon: "icon-arrow",
    title: "connect wallet & buy",
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const ref = localStorage.getItem("ref");
    if (token && userFriendlyAddress && ref) {
      fetch(`${SERVER_URL}/v1/user/refLink/${ref}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.ref_link) {
            setRefferer(data.ref_link.address);
          }
        });
    }
  }, []);

  const Button: any = STATUS_PRESALE[data.status].button;

  const handleClick = async () => {
    if (userFriendlyAddress) {
      await provideTon(
        tonConnectUi,
        Address.parse(data.contract_address),
        amount,
        Address.parse(refferer) || ""
      );
    } else {
      tonConnectUi.openModal();
    }
  };

  return (
    <div className="wallet-action br16">
      <div
        className={`wallet-action__header ${STATUS_PRESALE[data.status].class}`}
      >
        <p className="wallet-action__status">
          <span className={STATUS_PRESALE[data.status].class_icon}></span>
          {STATUS_PRESALE[data.status].title}
        </p>
        <Countdown date={data.end_time} />
      </div>
      <div className="wallet-action__body">
        <ProgressBar
          softCap={data.softcap}
          hardCap={data.hardcap}
          total={data.total}
        />
        {STATUS_PRESALE[data.status].hasInput && (
          <div className="wallet-action__field">
            <div className="input">
              <input
                value={amount}
                onChange={(e: any) => setAmount(e.target.value)}
                min={data.min_per_user}
                max={data.max_per_user}
                type="number"
              />
              <span>max: {fromNano(data.max_per_user)}</span>
            </div>
            <span>TON</span>
          </div>
        )}
        {Button !== false && (
          <button className="wallet-action__button" onClick={handleClick}>
            <span>
              {userFriendlyAddress ? Button.title : UnathorizeButton.title}
            </span>
            <span
              className={
                userFriendlyAddress
                  ? Button.class_icon
                  : UnathorizeButton.class_icon
              }
            ></span>
          </button>
        )}
      </div>
    </div>
  );
}
