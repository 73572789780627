import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.scss";
import "./css/icon.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { HelmetProvider } from "react-helmet-async";
import { SERVICE_URL } from "./constants";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <TonConnectUIProvider manifestUrl={`${SERVICE_URL}/tonconnect-manifest.json`}>
          <App />
        </TonConnectUIProvider>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
