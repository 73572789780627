import { SERVER_URL, STATUS_PRESALE } from '@/constants';
import '@/css/components/ui/Referal.scss'
import { claim } from '@/handlers/claim';
import { PresaleData } from '@/type/interface'
import { Address } from '@ton/ton'
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReferalLink from './ReferalLink';

interface ReferalProps {
    data: PresaleData
}

interface PresaleUser {
    address: string,
    claim_allow_time: string,
    claimed: true,
    contract_address: string,
    helper_address: string,
    referrer_address: string,
    total: number,
    total_ref: number
}


export const Referal = ({data}: ReferalProps) => {
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUi, setOptions] = useTonConnectUI();
    const [presaleUser, setPresaleUser] = useState<PresaleUser>()

    useEffect(() => {
        if(userFriendlyAddress && localStorage.getItem('token') && data.status === 3){
            axios.get(`${SERVER_URL}/v1/user/presale/${data.contract_address}`, {
                headers: {
                    'Authorization': `${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                }
            }).then(res => res.data).then(data => setPresaleUser(data.presale_user))
        }
    },[])


    const handleClick = async () => {
        if(presaleUser){
            await claim(tonConnectUi, Address.parse(presaleUser.helper_address))
        }
    }

    return(
        <div className="referal br16">
            <div className="referal__head">
                <span className='icon-present'></span>
                <span className='referal__reward'>Earn {data.referral_share/1000000000}% reward</span>
                <div className='referal__dot'></div>
                <span className='referal__link'>Share refferal link</span>
            </div>
            {
                userFriendlyAddress && 
                <ReferalLink text={userFriendlyAddress}/>
            }
            {
                userFriendlyAddress && data.status == 3 &&
                <div className='referal__reward'>
                    <span>Your rewards</span>
                    {presaleUser && <span>{presaleUser.total_ref} {data.jetton.symbol}</span>}
                </div>
            }
            {
                !userFriendlyAddress && 
                <button onClick={() => tonConnectUi.openModal()}  className='referal__button'>
                    <span>connect wallet & earn</span>
                    <span className='icon-arrow'></span>
                </button>
            }
            {
                userFriendlyAddress && data.status == 3 &&
                <button onClick={handleClick} className='referal__button'>
                    <span>Claim reward</span>
                    <span className='icon-arrow'></span>
                </button>
            }
        </div>
    )
}