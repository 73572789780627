import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "@/css/components/layout/Layout.scss";
import WithDurev from "@/components/WithDurev";
import FirstPreCard from "@/components/FirstPreCard";
import PreCard from "@/components/PreCard";
import mockData from "@/mockData";
import { firstPresalesFetch } from "@/store/presales";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { PresaleData } from "@/type/interface";
import { Helmet } from "react-helmet-async";

const Layout = () => {
  const presales = useAppSelector((state) => state.presales.value);
  const firstElement = presales[0];

  // Создаём новый массив из оставшихся элементов
  const remainingElements = presales.slice(1);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (presales.length === 0) {
      dispatch(firstPresalesFetch());
    }
  }, []);

  return (
    <div className="catalogue wrap">
      <Helmet>
        <title>durev launchpad</title>
        <meta name="description" content="durev launchpad." />
      </Helmet>
      {firstElement && <FirstPreCard data={firstElement} />}
      <WithDurev />
      {remainingElements &&
        remainingElements.map((el: PresaleData) => <PreCard key={el.contract_address} data={el} />)}
    </div>
  );
};
export default Layout;
