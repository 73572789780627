import { TokenomicsItem } from "@/type/interface";
import { AgCharts } from "ag-charts-react";
import { useState } from "react";
import '@/css/components/TokenomicBar.scss'

export default function TokenomicBar({data}:any) {
    const [chahtOptions, setChartOptions] = useState<any>({
        data: data,
        series:[{type: 'bar', xKey:'name', yKey:'value'}],
        background: {
            fill: 'transparent'
        }
    })
    return (
        <div className="tokenomic-bar br16">
            <h2 className="tokenomic-bar__title">Project Tokenomics</h2>
            <AgCharts options={chahtOptions}/>
        </div>
    )
}