import React from "react";
import Toast from "./Toast";

import '@/css/components/ui/Toast.scss'
import { useAppSelector } from "@/hooks/hooks";

interface ToastsMessage {
    id: number;
    message: string;
}

const ToastContainer:React.FC = () => {
    const toasts = useAppSelector(state => state.toast.value);
    return(
        <div className="toasts-container">  
            {toasts && toasts.map((toast: ToastsMessage) => 
                <Toast key={toast.id} text={toast.message} id={toast.id}/>
            )}
        </div>
    )
}

export default ToastContainer;