import '@/css/components/ui/SimpleField.scss'

interface SimpleFieldProps {
    title: string;
    value: string | number;
    clssName?: 'third' | 'half' | 'full' | 'quarter' | string;
}

export default function SimpleField({title, value, clssName}:SimpleFieldProps){
    return(
        <div className={`simple-field br16 ${clssName}`}>
            <p className="simple-field__title">{title}</p>
            <p className="simple-field__value">{value}</p>
        </div>
    )
}