import React, { useState, useEffect } from 'react';


interface CountdownProps {
    date: string
}


const Countdown: React.FC<CountdownProps> = ({date}) => {
  const endTime = new Date(date).getTime();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = endTime - now;

    let timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  }

  return (
    <div className='countdown'>
      {timeLeft.days} <span>days</span> {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours} <span>hrs</span>{' '}
      {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes} <span>min</span>{' '}
      {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds} <span>sec</span>
    </div>
  );
}

export default Countdown;