import { useAppDispatch } from "@/hooks/hooks";
import { addToast, removeToast } from "@/store/toast";

interface CopyFieldProps{
    text: string;
}

export default function CopyField({text}:CopyFieldProps){
    const dispatch = useAppDispatch()
    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
        const id = Date.now()
        const toast = {
            id: id,
            message: ('Copied')
        }
        dispatch(addToast(toast))
        setTimeout(() => dispatch(removeToast(id)), 3000)
    }

    return (
        <div onClick={() => copyToClipboard(text)} className="progress-line__item-value">
            <span className="value">{text}</span>
            <span className="icon-copy"></span>
        </div>
    )
}