import { PresaleData, TokenomicsItem } from "@/type/interface";
import "@/css/components/PresalePageComponent.scss";
import TitleCard from "./ui/TitleCard";
import SimpleField from "./ui/SimpleField";
import TokenomicBar from "./TokenomicBar";
import CopyField from "./ui/CopyField";

interface PresalePageComponentProps {
  data: PresaleData;
}

export default function PresalePageComponent({
  data,
}: PresalePageComponentProps) {
  const checkLiquidity = (): number | false => {
    if (data.tokenomics_items) {
      const liquidityItem = data.tokenomics_items.find(
        (item: TokenomicsItem) => item.is_liquidity === true
      );
      return liquidityItem ? liquidityItem.value : false;
    }
    return false;
  };
  const formatValue = (value: number, decimals: number) => {
    const dividedValue = value / Math.pow(10, decimals);
    // Форматируем значение с нужным количеством десятичных знаков
    const fixedValue = dividedValue.toFixed(decimals);
    // Убираем лишние нули после запятой
    return fixedValue.replace(/\.?0+$/, "");
  };
  const liquidityValue = checkLiquidity();

  return (
    <div className="presale-section br20">
      <div className="presale-section__image br16 presale-section__only-desktop">
        <img src={data.image_url} />
      </div>
      <div className="presale-section__header presale-section__only-desktop">
        <TitleCard data={data.jetton} showDescription={true} />
        <div className="presale-section__header__social">
          <a>
            <span className="icon-socials-Web br-c" />
          </a>
          <a>
            <span className="icon-telegram br-c" />
          </a>
          <a>
            <span className="icon-x br-c" />
          </a>
        </div>
      </div>
      <div className="presale-section__body">
        <SimpleField
          clssName="third"
          title="Token Name"
          value={data.jetton.name}
        />
        <SimpleField
          clssName="third"
          title="Token Symbol"
          value={data.jetton.symbol}
        />
        <SimpleField
          clssName="third mobile-half"
          title="Token Decimals"
          value={data.jetton.decimals}
        />
        <SimpleField
          clssName="half"
          title="Total Supply"
          value={`${formatValue(
            data.jetton.total_supply,
            data.jetton.decimals
          )} ${data.jetton.symbol}`}
        />
        <SimpleField
          clssName="half"
          title="Tokens For Presale"
          value={`${formatValue(data.tokens_for_sale, data.jetton.decimals)} ${
            data.jetton.symbol
          }`}
        />
        {liquidityValue && (
          <SimpleField
            clssName="half"
            title="Tokens For Liquidity"
            value={
              (data.jetton.total_supply * liquidityValue) / 100 +
              " " +
              data.jetton.symbol
            }
          />
        )}
        <SimpleField
          clssName="half"
          title="Initial Market Cap (estimate)"
          value={1000000 + " " + data.jetton.symbol}
        />
        <div className="br16 progress-line">
          <div className="progress-line__item">
            <span className="title">Presale Address</span>
            <CopyField text={data.contract_address} />
          </div>
          <div className="progress-line__item">
            <span className="title">Token Address</span>
            <CopyField text={data.jetton.address} />
          </div>
        </div>
        <SimpleField
          clssName="third mobile-full"
          title="Presale Start Time"
          value={data.start_time}
        />
        <SimpleField
          clssName="third"
          title="Presale End Time"
          value={data.end_time}
        />
        {liquidityValue && (
          <SimpleField
            clssName="quarter"
            title="Liquidity Percent"
            value={`${liquidityValue}%`}
          />
        )}
        {data.tokenomics_items && data.tokenomics_items.length > 0 && (
          <TokenomicBar data={data.tokenomics_items} />
        )}
      </div>
      <p className="presale-section__disclaimer">
        Disclaimer: durev launchpad will never endorse or encourage that you
        invest in any of the projects listed and therefore, accept no liability
        for any loss occasioned. It is the user(s) responsibility to do their
        own research and seek financial advice from a professional. More
        information about (DYOR) can be found via Binance Academy.
      </p>
    </div>
  );
}
