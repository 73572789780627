import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface ExchangeRateState {
  value: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ExchangeRateState = {
  value: 10,
  status: 'idle',
  error: null,
};

export const fetchExchangeRate = createAsyncThunk(
  'exchangeRate/fetchExchangeRate',
  async () => {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=povel-durev&vs_currencies=usd');
    return response.data['povel-durev'].usd;
  }
);

export const exchangeRatesSlice = createSlice({
  name: 'exchangeRate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExchangeRate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = parseFloat(action.payload.toFixed(3));
      })
      .addCase(fetchExchangeRate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch exchange rate';
      });
  },
});


export default exchangeRatesSlice.reducer;
