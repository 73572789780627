import React from "react";
import '@/css/static/Footer.scss';
import ExchangeRate from '@/components/footer/ExchangeRate';
import Subscribe from '@/components/footer/Subscribe';
const Footer = () => {
    return(
        <footer className="footer-main">
            <div className="wrap footer-main__first">
            <ExchangeRate />
            <Subscribe />
            </div>
            <div className="wrap footer-main__second">
                <p>© 2024 durev launchpad</p>
            </div>
        </footer> 
    );
};

export default Footer;