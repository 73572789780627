import {
    Sender,
    SenderArguments,
    beginCell,
    storeStateInit
} from '@ton/core';
import {TonConnectUI} from "@tonconnect/ui-react";

export class TonConnectSender implements Sender {
    constructor(
        private readonly tonConnect: TonConnectUI,
    ) {
    }

    async send (args: SenderArguments): Promise<void> {
        await this.tonConnect.sendTransaction({
            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
            messages: [
                {
                    address: args.to.toString(),
                    amount: args.value.toString(),
                    payload: args.body?.toBoc().toString('base64'),
                    stateInit: args.init
                        ? beginCell().store(storeStateInit(args.init)).endCell().toBoc().toString('base64')
                        : undefined,
                },
            ],
        });
    }
}