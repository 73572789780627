import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastsMessage {
    id: number;
    message: string;
}

interface ToastState {
    value: ToastsMessage[];
}

const initialState:ToastState = {
    value : [],
}

export const ToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast:(state, action: PayloadAction<ToastsMessage>) => {
        state.value = [...state.value, action.payload];
    },
    removeToast:(state, action: PayloadAction<number>) => {
        state.value = state.value.filter(toast => toast.id !== action.payload);
    }
  },
});

export const { addToast, removeToast } = ToastSlice.actions;

export default ToastSlice.reducer;
