import React, { useEffect } from 'react';
import '@/css/components/footer/subscribe.scss';
import { SUBSCRIBE_URLS } from '@/constants';
const Subscribe = () => {
    return(
        <div className="subscribe-durev">
            <p>Subscribe:</p>
            {SUBSCRIBE_URLS.map((item, index) => (
                    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                        <span className={item.icon_class}></span>
                    </a>
                ))}
        </div>
    );
};

export default Subscribe;