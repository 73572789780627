import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./css/App.scss";
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";
import Layout from "./components/layout/Layout";
import OneLayout from "./components/layout/OneLayout";
import ToastContainer from "./components/ui/ToastContainer";
import { useTonConnectUI, Wallet } from "@tonconnect/ui-react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { SERVER_URL, WS_URL } from "./constants";
import useWebSocket from "react-use-websocket";
import { useAppDispatch } from "./hooks/hooks";
import { PresaleData } from "./type/interface";
import { firstPresalesFetch, setPresale } from "./store/presales";
import { WebSocketLike } from "react-use-websocket/dist/lib/types";
import NotFoundPage from "./components/layout/NotFoundPage";

function App() {
  const [tonConnectUI] = useTonConnectUI();
  const socketUrl = WS_URL ? WS_URL : '';
  const dispatch = useAppDispatch();
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null); // Реф для хранения ID интервала
  const webSocketRef = useRef<WebSocketLike | null>(null); // Реф для хранения WebSocket соединения

  const { lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      onOpen: () => console.log(""),
      onError: (event) => {
        console.error("WebSocket error:", event);
        closeWebSocket();
      },
      onClose: () => {
        console.log("WebSocket closed");
        startPolling();
      },
      shouldReconnect: (closeEvent) => false,
    }
  );

  useEffect(() => {
    webSocketRef.current = getWebSocket(); // Сохраняем текущее WebSocket соединение
  }, [getWebSocket]);

  useEffect(() => {
    if (lastJsonMessage) {
      const changedPresale = lastJsonMessage as PresaleData;
      console.log(changedPresale);
      dispatch(setPresale(changedPresale));
    }
  }, [lastJsonMessage]);

  // Очищаем интервал при размонтировании компонента
  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  const closeWebSocket = () => {
    if (
      webSocketRef.current &&
      (webSocketRef.current.readyState === WebSocket.OPEN ||
        webSocketRef.current.readyState === WebSocket.CONNECTING)
    ) {
      webSocketRef.current.close();
    }
  };

  const startPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }

    pollingIntervalRef.current = setInterval(() => {
      dispatch(firstPresalesFetch());
    }, 3000);
  };

  async function getWalletConnection() {
    const response = await fetch(`${SERVER_URL}/v1/auth/payload`);
    const data = await response.json();
    tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: {
        tonProof: data.payload,
      },
    });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const searchParams = new URLSearchParams(window.location.search);
    const ref = searchParams.get("ref");
    if (ref) {
      localStorage.setItem("ref", ref);
    }
    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
      }
    } else {
      getWalletConnection();
    }
  }, []);

  useEffect(
    () =>
      tonConnectUI.onStatusChange((wallet: Wallet | null) => {
        getWalletConnection();
        if (
          wallet &&
          wallet.connectItems?.tonProof &&
          "proof" in wallet.connectItems.tonProof
        ) {
          try {
            fetch(`${SERVER_URL}/v1/auth`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
              },
              body: JSON.stringify({
                address: wallet.account.address,
                network: "-239",
                proof: wallet.connectItems.tonProof.proof,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.token) {
                  localStorage.setItem("token", data.token);
                  const decodedToken = jwtDecode<JwtPayload>(data.token);
                  // setIsAuth(true);
                } else {
                  // setIsAuth(false);
                }
              });
          } catch (e) {
            console.log(e);
          }
        } else if (!wallet) {
          // setIsAuth(false);
          localStorage.removeItem("token");
        }
      }),
    []
  );

  return (
    <div className="durev-app">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/:pageName" element={<OneLayout />} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      </Router>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
