import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { fetchExchangeRate } from '@/store/exchangeRates';
import { AppDispatch } from '@/store/store'; 
import Img from '@/assets/img.png';
import '@/css/components/footer/exchangeRate.scss';
import {BUY_DUREV} from '@/constants';

const ExchangeRate: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); 

  const { value, status, error } = useSelector(
    (state: RootState) => state.exchangeRate
  );

  useEffect(() => {
    dispatch(fetchExchangeRate());


    const interval = setInterval(() => {
      dispatch(fetchExchangeRate());
    }, 2 * 60 * 1000); // 2 минут * 60 секунд * 1000 миллисекунд

    // Возвращаем функцию очистки, чтобы остановить интервал при размонтировании компонента
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className='exchange-durev'>
        <div className='exchange-durev__rate'>
            <div className="logo br-c">
                <img src={Img} alt="durev logo" />
            </div>
            {status === 'loading' && <p>Loading...</p>}
            {status === 'failed' && <p>Error: {error}</p>}
            {status === 'succeeded' && (
                <div className='exchange-durev__value'>
                  <span>durev</span> 
                  <span className='exchange-durev__sign'>=</span>
                  <span>{value} $</span>
                </div>
            )}
       </div>
       <a className='exchange-durev__link-buy br25' href={BUY_DUREV.url} target='_blank'>
        <p>{BUY_DUREV.title}</p>
        <span className="icon-arrow br-c"></span>
       </a>
    </div>
  );
};

export default ExchangeRate;
