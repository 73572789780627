import { Address, beginCell, Cell, Contract, ContractProvider, Sender, SendMode } from '@ton/core';

export class Master implements Contract {
    constructor(
        readonly address: Address,
        readonly init?: { code: Cell; data: Cell },
    ) {}

    static createFromAddress(address: Address) {
        return new Master(address);
    }

    async sendProvideTon(
        provider: ContractProvider,
        via: Sender,
        value: bigint,
        queryId: bigint,
        amount: bigint,
        referrer?: Address,
    ) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell()
                .storeUint(0x7f486350, 32)
                .storeUint(queryId, 64)
                .storeCoins(amount)
                .storeAddress(referrer)
                .endCell(),
        });
    }

    async getHelperOf(provider: ContractProvider, address: Address) {
        return (
            await provider.get('get_helper', [{ type: 'slice', cell: beginCell().storeAddress(address).endCell() }])
        ).stack.readAddress();
    }
}
