import React from 'react';
import { Jetton } from '@/type/interface';
import '@/css/components/ui/TitleCard.scss'
interface TitleCardProps {
  data: Jetton;
  showDescription?: boolean;
}

const TitleCard: React.FC<TitleCardProps> = ({ data, showDescription = false }) => {
  const { description, name, image_url } = data;

  return (
    <header className='pre-card__title'>
      <div className='pre-card__title__logo br-c'>
        <img src={image_url} alt={`${name} logo`} />
      </div>
      <div className='pre-card__title__info'>
        <h1>{name}</h1>
        {showDescription && <p>{description}</p>}
      </div>
    </header>
  );
};

export default TitleCard;
