import React from 'react';
import PreCard from './PreCard';
import { PresaleData } from '@/type/interface';
import '@/css/components/FirstPreCard.scss';
interface PreCardProps {
    data: PresaleData;
}
const FirstPreCard: React.FC<PreCardProps> = ({data}) => {

return(
    <div className='br20 first' style={{backgroundImage: `url(${data.image_url})`}}>
        <PreCard data={data} />
    </div>
);

};

export default FirstPreCard;