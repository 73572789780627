import { combineReducers } from '@reduxjs/toolkit';
import exchangeRates from './exchangeRates';
import presales from './presales';
import toast from './toast';

const rootReducer = combineReducers({
    exchangeRate: exchangeRates,
    presales: presales,
    toast: toast
});

export default rootReducer;