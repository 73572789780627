import React from "react";
import "@/css/static/Header.scss";
import { TonConnectButton } from "@tonconnect/ui-react";

import Img from "@/assets/img.png";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <header className="wrap main-header">
      <div className="logo" onClick={handleLogoClick}>
        <div className="logo-img br-c">
          <img src={Img} alt="My Image" />
        </div>
        <div className="logo-text br25">durev launchpad</div>
      </div>
      <TonConnectButton />
    </header>
  );
};

export default Header;
