// Получить свои токены и награду за реферальную систему
// В ситуациях, если пресейл провалился:
// - пользователь получит свои средства обратно
// ВАЖНО! Будет доступно только на фазе "claim" или "failed"
import {CLIENT_ENDPOINT} from "@/constants";
import {TonClient} from "@ton/ton";
import {TonConnectUI} from "@tonconnect/ui-react";
import {Address, toNano} from "@ton/core";
import {TonConnectSender} from "@/contracts/senders/TonConnectSender";
import {Helper} from "@/contracts/wrappers/Helper";

export const claim = async (tonConnect: TonConnectUI, helperAddress: Address) => {
    const client = new TonClient({
        endpoint: CLIENT_ENDPOINT,
    });

    const sender = new TonConnectSender(tonConnect)
    const contract = Helper.createFromAddress(helperAddress)
    const openedContract = client.open(contract)

    // query_id опциональный параметр, который облегчает индексацию блокчейна в некоторый случаях
    // Например: получить всю цепочку транзакций.
    const queryId = BigInt(Math.floor(Math.random() * 100000000))

    // 0.1 TON - network fee
    await openedContract.sendClaim(sender, toNano("0.1"), queryId)
}